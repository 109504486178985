import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h1 className="mt-3">Privacy Policy</h1>
      <p>Last updated Jan, 2023.</p>
      <p>
        Through our mobile application (the “App”) and website (the “Site”),
        Gratif, and Mountainscale private limited trading as Gratif (“Gratif,”
        “we,” “us,” or “our”) allows customers (“Customers”) and photographers
        (“Photographers”) to book photography engagements (the “Service”). This
        Privacy Policy describes the information that alone or in combination
        with other information could be used to identify you (“Personal Data”)
        that Gratif collects, how we use and share that data, and your choices
        concerning our data practices.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        The Personal Data we collect varies based on who you are and what you
        choose to share with us. When you contact us or interact with our
        Service, we collect categories of Personal Data as follows:
      </p>

      <h3>Account Data</h3>
      <p>
        When you create an account or start using the Service, we will generally
        collect your name, email, phone number, mailing address, your address
        book (if you are a Customer and you consent), and, if you are a
        Photographer, profile information (including a photograph and a
        description of your expertise).
      </p>

      <h3>Payment Data</h3>
      <p>
        We collect certain payment and billing information needed for us to (i)
        pay Photographers and (ii) accept payments from Customers. This
        information may include billing address, payment card or PayPal details,
        and, if you are a Photographer, your social security or tax
        identification number. We collect Payment Data via secure payment
        processing services.
      </p>

      <h3>Photograph Data</h3>
      <p>
        We collect details relating to shoots you book through the Service
        (i.e., the time and place of the shoot) and we collect and store the
        photographs that Photographers take for Customers. In addition,
        Customers in certain jurisdictions can make use of tools enabled by
        facial recognition. (Please note that facial recognition tools are only
        available in Australia and certain areas of the United States.) These
        tools will identify similar faces in the Customer’s photographs. The
        Customer can then tag someone, at which point the Service will identify
        the other photographs in which the tagged person appears and allow the
        Customer to easily share those photographs with the tagged person.
      </p>

      <h3>Communication Data</h3>
      <p>
        We may collect information when you contact us with questions or
        concerns and when you voluntarily respond to questionnaires, surveys, or
        requests for market research seeking your opinion and feedback.
        Providing this information is optional to you.
      </p>

      <h3>Social Media Data</h3>
      <p>
        We have pages on social media sites like Instagram, LinkedIn, Twitter,
        and LinkedIn (“Social Media Pages”). When you interact with our Social
        Media Pages, we will collect Personal Data that you elect to provide to
        us through your settings on the Social Media Site, such as your contact
        details. In addition, the companies that host our Social Media Pages may
        provide us with aggregate information and analytics regarding the use of
        our Social Media Pages.
      </p>

      <h3>Third Party Data</h3>
      <p>
        We may receive information about Customers from third parties based on
        their professional email address, including your job title, employer,
        and location of work.
      </p>

      <h3>Background Check Data</h3>
      <p>
        For Photographers, we may collect information from third-party service
        providers in connection with performing background checks.
      </p>

      <h3>Online Activity Data</h3>
      <p>
        When you visit, use, and interact with the Site or App, we may
        automatically receive certain information about your visit, use, or
        interactions. For instance, we monitor the number of people visiting our
        Site, peak visit hours, visited page(s), visitor domains (e.g.,
        google.com, yahoo.com, etc.), and browsers used to access and visit our
        Site (e.g., Firefox, Microsoft Internet Explorer, etc.), along with
        broad geographical information and Site-navigation patterns.
      </p>

      <p>
        The following information is created and automatically logged in our
        systems when you use the Site:
      </p>

      <h4>Log Data:</h4>
      <p>
        Information that your browser automatically sends whenever you visit the
        Site ("log data"). Log data includes your Internet Protocol ("IP")
        address (to understand your connecting country), browser type and
        settings, the date and time of your request, and your interactions with
        the Site.
      </p>

      <h4>Cookies:</h4>
      <p>
        We and our partners use cookies to operate and administer our Site, make
        your future visits easier, and gather usage data. For more information
        about the cookies used on our Site, refer to our Cookie Policy, part of
        this Privacy Policy.
      </p>

      <h4>Device Information:</h4>
      <p>
        Includes the device name, operating system, and browser you use.
        Information collected depends on your device type and its settings.
      </p>

      <h4>Usage Information:</h4>
      <p>
        We collect information about how you use our Site, such as viewed or
        engaged content, features used, actions taken, and the time, frequency,
        and duration of your activities.
      </p>

      <p>
        The following information is automatically logged in our systems when
        you use the App:
      </p>

      <h4>Device Information:</h4>
      <p>
        We may collect information about your device, including the IP address,
        device type, non-permanent identifiers (i.e., advertising ID), operating
        system version, data connection type (WiFi/mobile data), and your mobile
        service provider's name. Information collected depends on your device
        type and its settings.
      </p>

      <h4>Usage Information:</h4>
      <p>
        We collect information related to your actions through the App,
        including navigation, time spent, and diagnostics during error
        experiences (crash/error reporting).
      </p>

      <h2>2. How We Use Personal Data</h2>
      <p>
        We use Personal Data to provide the Service, connecting Customers to
        book photoshoots, ensuring Photographer payments, and facilitating photo
        transmission. This processing is necessary to perform our contract with
        you.
      </p>

      <p>We also use Personal Data for legitimate business interests:</p>

      <ul>
        <li>To contact and communicate with you.</li>
        <li>
          To manage our relationship with you, including record-keeping and
          sending administrative information.
        </li>
        <li>
          To analyze how you interact with our Service, improve content and
          functionality, and inform marketing strategy.
        </li>
        <li>
          For market research, business/website development, and to administer
          and protect our business and the Site.
        </li>
      </ul>

      <p>
        For more details on legitimate interests and EU Users' rights, see the
        "EU Users" section below.
      </p>

      <h3>Marketing</h3>
      <p>
        We may contact you with information we believe is of interest,
        respecting applicable laws. You can opt out of receiving emails through
        provided instructions or by contacting us.
      </p>

      <h2>3. How We Share and Disclose Personal Data</h2>
      <p>
        In certain circumstances, we may share your Personal Data with third
        parties as outlined below:
      </p>

      <h3>Vendors</h3>
      <p>
        To assist in business operations, we share Personal Data with vendors
        (e.g., database, hosting providers), who process data per our
        instructions.
      </p>

      <h3>Business Transfers</h3>
      <p>
        In events like mergers or asset sales, Personal Data may be shared in
        the diligence process and transferred to a successor or affiliate.
      </p>

      <h3>Legal Requirements</h3>
      <p>
        If required by law or to protect rights, property, safety, or meet legal
        obligations.
      </p>

      <h2>4. Data Retention</h2>
      <p>
        We retain Personal Data as long as reasonably necessary for the
        described purposes or as required by law.
      </p>

      <h2>5. Update Your Information</h2>
      <p>
        If you need to change or correct your Personal Data, contact us, and we
        will address your request as required by law.
      </p>

      <h2>6. Children</h2>
      <p>
        Our Service is not directed to children under 16. We do not knowingly
        collect Personal Data from children under 16. If you believe a child
        under 16 provided Personal Data, contact us, and we will aim to delete
        it.
      </p>

      <h2>7. International Transfer</h2>
      <p>
        If accessing our Service from the EU, US, or other regions, your
        Personal Data will be transferred to and stored in India. We take steps
        to ensure data protection consistent with applicable laws.
      </p>

      <h2>8. Links to Other Websites</h2>
      <p>
        The Site may contain links to Third Party Sites. Your interactions with
        Third Party Sites are governed by their specific privacy policies and
        terms of service, not ours.
      </p>

      <h2>9. Security</h2>
      <p>
        While we comply with industry standards to protect Personal Data, use
        the Service at your own risk. No Internet transmission is fully secure.
        We are not responsible for circumvention of privacy settings or security
        measures.
      </p>

      <h2>10. Changes to the Privacy Policy</h2>
      <p>
        Our Service and business may change, leading to updates to this Privacy
        Policy. By continuing to use our Service or providing Personal Data
        after changes, you consent to the revised Privacy Policy.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        For questions about our Privacy Policy or the Site's information
        practices, contact us via{" "}
        <a
          href="https://www.gratif.in/contact-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.gratif.in/contact-us
        </a>
      </p>
      <h2>12. Privacy Options</h2>
      <p>
        Our aim in showcasing client images online is to promote the diversity,
        skill, and potential of our photography services. Displaying our work on
        social media and our website serves as a valuable marketing tool,
        attracting potential clients who resonate with our style.
      </p>
      <p>
        By using the services provided by Gratif or its partners, you
        acknowledge and agree that any photographs or videos taken by Gratif or
        its partners may be used for portfolio or commercial purposes. This
        includes, but is not limited to, showcasing our work on our website,
        social media, marketing materials, and other promotional platforms.
        Gratif reserves the right to use these images without requiring a model
        release or any further consent from individuals or entities featured in
        the content, unless otherwise agreed upon in writing.
      </p>
      <p>
        However, if you prefer to keep your images off our online platforms, we
        offer the following options:
      </p>
      <p>
        <strong>Cooling Period:</strong> Your images will not be used online for
        3 months after the shoot. After this period, we will have permission to
        feature them online or offline. This service is provided at no extra
        cost.
      </p>
      <p>
        <strong>Complete Privacy:</strong> Opt for full privacy by purchasing
        this option at an additional 15% of the original package price. This
        compensates for the inability to showcase the creativity with your
        images.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
